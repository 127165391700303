<span
  class="navBtn"
  [class.disabled]="disabled"
  [id]="'navButton-' + icon"
  [class.upstart]="isUpstart"
  [class.resize]="resize">
  <div class="navBtn_top">
    <cl-icon
      class="navBtn_top--icon desktop"
      [icon]="icon"
      [ngStyle]="{ width: '64px', height: '64px' }"
      size="64px"></cl-icon>
    <cl-icon
      class="navBtn_top--icon mobile"
      [icon]="icon"
      [ngStyle]="{ width: '42px', height: '42px' }"
      size="42px"></cl-icon>
    <h4 class="navBtn_top--title">{{ title }}</h4>
  </div>
  <div class="navBtn_desc">
    <span class="navBtn_desc--text">{{ desc }}</span>
    <div
      *ngIf="selected && isUpstart"
      class="navBtn_desc--btn navBtn_desc--btn--selected">
      <cl-icon
        [icon]="buttonIconSelected"
        size="16px"></cl-icon>
      <div>
        {{ buttonLabelSelected }}
      </div>
    </div>
    <div
      *ngIf="!selected && isUpstart"
      class="navBtn_desc--btn">
      {{ buttonLabel }}
    </div>
  </div>
</span>
