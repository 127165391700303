import { Component, HostBinding, Input } from '@angular/core';
import { Icon } from '@wam/icons';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Theme, ThemingService } from '@wam/theming';

@Component({
  selector: 'cl-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss'],
})
export class NavigationButtonComponent {
  @Input() icon: Icon;
  @Input() title: string;
  @Input() desc: string;
  @Input() buttonLabel: string;
  @Input() buttonLabelSelected: string;
  @Input() buttonIconSelected: Icon;
  @Input() selected = false;
  @Input() disabled: boolean;
  @Input() resize = false;

  constructor(
    private sanitizer: DomSanitizer,
    private themingService: ThemingService,
  ) {}

  @HostBinding('attr.style')
  get valueAsStyle(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `--host-pointer-events: ${this.disabled ? 'none' : 'auto'}`,
    );
  }

  get isUpstart(): boolean {
    return this.themingService.theme === Theme.UPSTART;
  }
}
